<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-09-18 17:37:38
 * @LastEditTime: 2021-11-04 14:26:08
 * @Description: 弹出多表单+附件 查看场景
 * @Param:
 * @FilePath: \JT_Web_test\src\components\popUpForm\index_see.vue
 -->

<template>
    <div class="popUpForm popUpForm_see" v-loading="loading">
        <div class="content" :class="{'attachmentView': formType == 'enclosure' || !isMaintain}">
            <el-tabs v-model="activeName" @tab-click="formSwitch">
                <template v-for="(item, index) in navData">
                    <el-tab-pane
                        :name="`page_type_${item.page_type}`"
                        :key="`formNav_${index}`"
                    >
                        <span slot="label" v-if="item.page_type != 'enclosure'">
                            <i class="iconfont iconbiaodan"></i>{{ item.page_name }}
                        </span>
                        <span slot="label" v-else>
                            <i class="iconfont iconwenjianjia"></i>附件资料
                        </span>
                    </el-tab-pane>
                </template>
            </el-tabs>
            <div
                class="foneContent"
                :class="{'foneContentStyle': formType === '0'}"
                :is="pagePath"
                :form-data="formData"
                :extr="extr"
                :business-instance-id="businessInstanceId"
                :is-read="true"
            ></div>
        </div>
        <footer v-if="formType != 'enclosure' && isMaintain">
            <el-button
                type="primary"
                class="save"
                @click="save"
                :loading="saveBtnLoading"
            >
                保存
            </el-button>
            <el-button
                type="primary"
                class="save"
                @click="Printing"
            >
                打印
            </el-button>
            <el-button
                class="Close"
                @click="Close"
            >
                关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
import store from '@/store';

export default {
    components: {},
    props: {
        code: {
            type: [Array, Object, String, Function],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            // 加载中状态
            loading: false,
            // 页面数据集合
            pageData: [],
            // tab数据
            navData: [],
            // 默认展开tab页
            activeName: '',
            // 页面路径
            pagePath: '',
            // 表单类型，0 配置表单，1vue表单
            formType: '',
            // 配置表单id
            frameId: '',
            // 配置表单传入参数
            formData: {
                FormUrl: '',
                FormKeyValue: '',
                IsRead: true,
            },
            // 自定义表单传入参数
            extr: {
                contract_number: '',
            },
            // 流程id 附件传入参数
            businessInstanceId: '',
            // 是否是维护
            isMaintain: null,
            // 保存按钮状态
            saveBtnLoading: false,
        };
    },
    async created() {
        // 合同台账维护
        if (this.code?.viewScene === 'maintain') {
            this.isMaintain = true;
        } else {
            this.isMaintain = null;
        }
        if (this.code.contractNumber) {
            this.extr.contract_number = this.code.contractNumber;
            this.loading = true;
            this.pageData = await this.getformData();
            if (Object.keys(this.pageData).length > 0) {
                this.processingFormData(this.pageData);
            }
            this.loading = false;
        }
    },
    methods: {
        // 处理表单数据
        processingFormData(data) {
            this.navData.push(...data.pageList, {
                page_name: '附件资料',
                page_type: 'enclosure',
            });
            if (this.isMaintain) {
                this.formData.IsRead = false;
                this.navData = this.navData.filter(item => !item.page_url || item.page_url.indexOf('salesBusiness/') === -1);
                this.navData.forEach(item => {
                    if (item.page_url) {
                        item.page_url = `${item.page_url}&ViewCode=adjustmonomer&RightGroupCode=adjustmonomer`;
                    }
                });
            }
            this.businessInstanceId = data.business_instance_id;
            this.activeName = `page_type_${data.pageList[0].page_type}`;
            this.processFormPath(data.pageList[0]);
        },

        // 表单切换
        formSwitch(tabs) {
            const row = this.navData[tabs.index];
            this.processFormPath(row);
        },

        // 处理表单路径
        processFormPath(row) {
            let myComponent = {};
            this.formType = row.page_type;
            if (row.page_type === '0') {
                myComponent = () => import('@/components/form/formPage.vue');
                this.formData.FormUrl = row.page_url;
                this.formData.FormKeyValue = row.key_value;
            } else if (row.page_type === '1') {
                myComponent = () => import('@/views/' + row.page_url + '.vue');
                this.extr.formInfo = row;
            } else if (row.page_type === 'enclosure') {
                myComponent = () => import('./annex/index_see.vue');
            }
            this.frameId = `frameId${this.$queryString(this.formData.FormUrl, 'FormCode')}`;
            this.pagePath = myComponent;
        },

        // 保存表单
        save() {
            this.loading = true;
            this.saveBtnLoading = true;
            const iframe = window.document.getElementById(this.frameId);
            iframe.contentWindow.JyFormSaveFormData(this.JTFlowAfterFormSaveSuccess, this.errorCallback);
        },
        // 保存表单成功回调
        JTFlowAfterFormSaveSuccess(PKValues) {
            this.saveBtnLoading = false;
            this.loading = false;
            this.formId = PKValues.PKValues;

            store.state.currentOpenList.forEach(item => {
                item.tableObj.updateTable();
            });
        },
        // 保存失败回调
        errorCallback() {
            this.saveBtnLoading = false;
            this.loading = false;
        },
        // 取消添加关闭弹出层
        Close() {
            this.$parent.hide();
        },
        // 打印
        Printing() {
            const iframe = window.document.getElementById(this.frameId);
            iframe.contentWindow.JyFormPrintForm();
        },

        // 获取流程表单数据
        getformData() {
            return this.$axios.$get(`/interfaceApi/sale/contract_manager/contract/current_page_data/${this.code.contractNumber}`, { defEx: true });
        },
    },
};
</script>

<style lang="stylus">
.popUpForm_see
    .content
        padding 0.14rem 0 0 0
        &.attachmentView
            height: 100%
        .el-tabs
            width 100%
            height 0.47rem !important
            .el-tabs__content
                display none
        .foneContent
            height calc(100% - 0.47rem) !important
            background: #fff
            &#annexData
                background: #fff
</style>
